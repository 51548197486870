<template>
  <div>
    <a-modal
        :width="900"
        v-model="show"
        title="物流信息"
    >
      <div class="logistics-content">
        <div class="content-top" v-if="popupData.gradeLogistics">
          <div>
            <div class="top-user-info">
              <div class="top-user-text">姓名：<span>{{ popupData.gradeLogistics.consigneeRealname }}</span></div>
              <div class="top-user-text ml-20">电话：<span>{{ popupData.gradeLogistics.consigneeTelphone }}</span></div>
              <div class="top-user-text ml-20">物流公司：<span>{{ popupData.gradeLogistics.logisticsName }}</span></div>
              <div class="top-user-text ml-20">物流单号：<span>{{ popupData.gradeLogistics.expressNo }}</span></div>
            </div>
            <div class="top-user-address">
              <div>详情地址：<span>{{ popupData.gradeLogistics.consigneeAddress }}</span></div>
            </div>
          </div>
          <div v-if="showType">
            <a-button
                class="mt-10"
                size="small"
                type="danger"
                @click="handleChangeReturnAddressId(popupData.gradeLogistics)"
            >修改退货地址</a-button>
          </div>
        </div>
        <div class="content-time-line" v-if="popupData.trackArray">
          <a-timeline>
            <a-timeline-item
              v-for="(item, index) in popupData.trackArray"
              :key="index"
              :color="index === 0 ? 'blue' : 'gray'"
            >
              <p :class="index === 0 ? 'color-black' : ''">{{ item.context }}</p>
              <p class="color-gary">{{ item.time }}</p>
            </a-timeline-item>
          </a-timeline>
        </div>
        <div class="color-gary null-data" v-else>暂无物流信息~</div>
      </div>
    </a-modal>
<!--  修改物流信息  -->
    <ChangeLogisticsPopup ref="changeLogisticsPopupEl" @changeSuccess="handleChangeSuccess"/>
  </div>
</template>
<script>
import {getLogisticsInfoApi} from "@/views/cmsPage/checkManage/_apis"
import ChangeLogisticsPopup from "@/views/cmsPage/checkManage/ratingManage/_components/ChangeLogisticsPopup"
export default {
  components: {ChangeLogisticsPopup},
  data() {
    return {
      show: false,
      popupData: '',
      recordId: '',
      showType: ''
    };
  },
  methods: {
    /** 修改退货物流 */
    handleChangeReturnAddressId(row) {
      this.$refs.changeLogisticsPopupEl.show(row, this.ratingId)
    },
    /** 修改成功 */
    handleChangeSuccess() {
      this.getLogisticsInfo()
    },
    async showPopup(id, type, ratingId) {
      this.ratingId = ratingId || ''
      this.showType = type
      this.recordId = id
      await this.getLogisticsInfo()
      this.show = true
    },
    /** 获取数据 */
    async getLogisticsInfo() {
      this.$loading.show()
      const res = await getLogisticsInfoApi({ orderGradeLogisticsId: this.recordId })
      this.$loading.hide()
      if (res.status !== '200') return
      this.popupData = res.data
    },
    /** 取消 */
    handleCancelPrint() {
      this.show = false
      this.popupData = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.null-data {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.content-time-line {
  margin-top: 20px;
  width: 100%;
}
.content-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .top-user-address {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    color: gray;
    span {
      color: black;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .top-user-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .top-user-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      color: gray;
      span {
        color: #008a00;
        font-weight: bold;
      }
    }
  }
}
.logistics-content {
  width: 100%;
}
</style>
